.text-tiny {
    font-size: .7em;
  }
  
  .text-small {
    font-size: .85em;
  }
  
  .text-big {
    font-size: 1.4em;
  }
  .text-huge {
    font-size: 1.8em;
  }

  .image {
    display: table;
    clear: both;
    text-align: center;
    margin: 0.9em auto;
    min-width: 50px;
  }
  
  .image-style-side {
    float: right;
    margin-left: var(--ck-image-style-spacing);
    max-width: 50%;
  }

  img {
    max-width: 100%;
    height: auto;
  }
  